export enum ProposalStatusEnum {
	Draft           = 'draft',
	Submitted       = 'submitted',
	Viewed          = 'viewedbyclient',
	EmailFailed     = 'emailfailed',
	Accepted        = 'accepted',
	Declined        = 'declined',
	Delayed         = 'delayed',
	ChangesRequired = 'changesrequired',
	Completed       = 'completed',
	Expired         = 'expired',
}


export enum AreaItemTypeEnum {
	Part   = 'Part',
	Labor  = 'Labor',
	Custom = 'CustomItem',
	Fee    = 'Fee',
}


export enum AreaOptionStatusEnum {
	Draft    = 'DRAFT',
	Accepted = 'ACCEPTED',
	Declined = 'DECLINED',
}


export enum PaymentTypeEnum {
	Cc  = 'CreditCard',
	Ach = 'ACH',
}


export enum ProposalTaxApplyToEnum {
	Labor = 'labor',
	Part  = 'part',
	Fee   = 'fee',
}


export enum ContactTypeEnum {
	Person  = 'person',
	Company = 'company',
}


export enum PaymentScheduleItemTypeEnum {
	Percentage = 'PERCENTAGE',
	Fixed      = 'FIXED',
	Remaining  = 'REMAININGBALANCE',
}


export enum PaymentSchedulePercentageBasisEnum {
	ProposalTotal = 'PROPOSALTOTAL',
	PartsTotal    = 'PARTTOTAL',
	LaborTotal    = 'LABORTOTAL',
}


export enum CloudinaryPathEnum {
	Parts              = 'SupplyStream/Part',
	Company            = 'SupplyStream/Company',
	Category           = 'SupplyStream/Category',
	WebContentAssets   = 'WebContent/Assets',
	WebContentTeam     = 'WebContent/Team',
	Placeholder        = 'WebContent:Assets:placeholder.png',
	LaborPlaceholder   = 'WebContent:Assets:wrench.png',
	FeePlaceholder     = 'WebContent:Assets:fee-big.svg',
	CompanyPlaceholder = 'WebContent:Assets:emptyCompany.png',
}


export enum TransactionStatusEnum {
	Undefined      = 'UNDEFINED',
	Draft          = 'DRAFT',
	Submitted      = 'SUBMITTED',
	Viewed         = 'VIEWED',
	Verifying      = 'VERIFYING',
	Paid           = 'PAID',
	Declined       = 'DECLINED',
	Refunded       = 'REFUNDED',
	Pending        = 'PENDING',
	RequiresAction = 'REQUIRESACTION',
}


export enum GatewayTypeEnum {
	Custom = 'NONE',
	Stripe = 'STRIPE',
}


export enum DiscountTypeEnum {
	Fixed      = 'FIXED',
	Percentage = 'PERCENTAGE',
}


export enum PaymentScheduleMilestoneTypeEnum {
	ProposalAcceptance = 'PROPOSALACCEPTANCE',
	JobStart           = 'JOBSTART',
	JobCompletion      = 'JOBCOMPLETION',
	CustomMilestone    = 'CUSTOMMILESTONE',
}


export enum ChangeOrderItemActionEnum {
	Added          = 'ITEMADDED',
	Refunded       = 'ITEMREFUNDED',
	Replaced       = 'ITEMREPLACED',
	PriceChanged   = 'PRICECHANGED',
	PriceDecreased = 'PRICEDECREASED',
	PriceIncreased = 'PRICEINCREASED',
	QtyDecreased   = 'QTYDECREASED',
	QtyIncreased   = 'QTYINCREASED',
	Changed        = 'CHANGED',
	MiscChanges    = 'MISCCHANGES',
}
